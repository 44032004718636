const videos = [
    {
        vid_id : 1,
        card_id: "c1",
        title  : "Filhaal2 Mohabbat | Akshay Kumar Ft Nupur Sanon | Ammy Virk | BPraak | Jaani | Arvindr Khaira",
        img    : "https://i.ytimg.com/vi/DUwlGduupRI/mqdefault.jpg",
        views  : "457M",
        time   : "2 week ago",
        link   : "https://www.youtube.com/watch?v=DUwlGduupRI",
        length : "5:25",
        tags   : "Akshay Kumar,Love Songs,Bollywood Songs,Desi Melodies,BPraak,Gaana,Music",
        channel_name : "DM - Desi Melodies",
        channel_logo : "https://yt3.ggpht.com/ytc/AKedOLRPJyyEEd5XdL34-6Pzz4KVkpXrTuU3MxOFN6JW=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 2,
        card_id: "c2",
        title  : "Sakhiyan2.0 | Akshay Kumar | BellBottom | Vaani Kapoor | Maninder Buttar | Tanishk B | Zara K |Babbu ",
        img    : "https://i.ytimg.com/vi/PO6nynliE0Q/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBL43CEb6u_mdgngrlqUPvkQVi68Q",
        views  : "75M",
        time   : "1 month ago",
        link   : "https://www.youtube.com/watch?v=PO6nynliE0Q",
        length : "3:16",
        tags   : "Akshay Kumar,Love Songs,Gaana,Punjabi Song,Music,Bollywood Songs,Saregama Music,Maninder Buttar",
        channel_name : "Saregama Music",
        channel_logo : "https://yt3.ggpht.com/ytc/AKedOLSGzxiENFZh-9_LqLnaObU9IWtFVNxT4T0m_oNKWQ=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 3,
        card_id: "c3",
        title  : "React Router Tutorial in Hindi | React Router Dom in Hindi in 2020 #56",
        img    : "https://i.ytimg.com/vi/1N_Vh0bRK3c/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDPMDvwilyAd7IsgYoiiiM_mJtPHw",
        views  : "98K",
        time   : "1 year ago",
        link   : "https://www.youtube.com/watch?v=1N_Vh0bRK3c&t=1327s",
        length : "25:23",
        tags   : "Coding,Thapa Technical,React js",
        channel_name : "Thapa Technical",
        channel_logo : "https://yt3.ggpht.com/ytc/AKedOLTBZVVSj01oheKt9M8MtFhxKyy0qPBqzUn9SByvYg=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 4,
        card_id: "c4",
        title  : "KAKA : MERE WARGA (Official Video) Sukh-E | New Punjabi Songs 2021 | Latest Punjabi Songs 2021",
        img    : "https://i.ytimg.com/vi/e-TuBq5QTO0/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC66xN63MfzAsSCoZvrTufxNul7XA",
        views  : "26M",
        time   : "2 months ago",
        link   : "https://www.youtube.com/watch?v=e-TuBq5QTO0",
        length : "4:46",
        tags   : "Love Songs,Gaana,Music,Punjabi Songs,Kaka Songs,Times Music",
        channel_name : "Times Music",
        channel_logo : "https://yt3.ggpht.com/ytc/AKedOLS1T7s1Ad-mCSCy-MaKHynhn3gntNevxlDKDZKOSQ=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 5,
        card_id: "c5",
        title  : "Lehanga : Jass Manak (Official Video) Satti Dhillon | Latest Punjabi Songs | GK DIGITAL | Geet MP3",
        img    : "https://i.ytimg.com/vi/RKioDWlajvo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC2PyriM03OpNwPD0li4JEQAt5O-g",
        views  : "1.3B views",
        time   : "2 year ago",
        link   : "https://www.youtube.com/watch?v=RKioDWlajvo",
        length : "3:46",
        tags   : "Jass Manak,Geet MP3,Music,Love Songs,Gaana,Music,Punjabi Songs",
        channel_name : "Geet MP3",
        channel_logo : "https://yt3.ggpht.com/ytc/AKedOLQ2TOdK0LZQGzyAFWFLiJk1NLspyvdoUsmZ9RjTRw=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 6,
        card_id: "c6",
        title  : "Youngistan | Devender Ahlawat | Ash Chhikara | 3rdeyemuzic | new haryanvi songs haryanavi",
        img    : "https://i.ytimg.com/vi/xHTuFpX2m6U/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCPNNEQkHF_5aZVQEtV0xO-ZxDtEg",
        views  : "2.4M",
        time   : "10 months ago",
        link   : "https://www.youtube.com/watch?v=xHTuFpX2m6U",
        length : "3:28",
        tags   : "Devendra Ahlawat,HR Songs,Music,Love Songs,Gaana,Street Music",
        channel_name : "Street Music Studios",
        channel_logo : "https://yt3.ggpht.com/q8B5nABqwGPoBqgDMA8ReWybdbctce6d4fQeODi-hT8NTbjmSpPQM7NChukIsYGIgLEXaohJ=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 7,
        card_id: "c7",
        title  : "भाई रे तेरे दो दो साली | BHAI RE TERE DO DO SAALI - POPULAR HARYANVI DJ SONG | RAJESH SINGHPURIA",
        img    : "https://i.ytimg.com/vi/hke-c2Pxu1E/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCqIqygsXdhyY-kjIZv3dClcjM_Ew",
        views  : "6.6M",
        time   : "6 year ago",
        link   : "https://www.youtube.com/watch?v=hke-c2Pxu1E",
        length : "4:29",
        tags   : "Rajesh Singpuriya,HR Songs,Music,Gaana",
        channel_name : "Supertone Digital",
        channel_logo : "https://yt3.ggpht.com/mPSUO4nGba9EU3VQwGjKOnhF6cIE4pdtVGQS5jW8O-ENwS1SqyZvArIzT12DUDXStnITYIYZ=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 8,
        card_id: "c8",
        title  : "Superhit Haryanvi Song | Kothe Chad Lalkaru | कोठे चढ़ ललकारु | Surender Romio",
        img    : "https://i.ytimg.com/vi/9dXnqKWtu1M/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBIOmWlhl96SMqV9I64D2P5EcUUWg",
        views  : "16M",
        time   : "7 year ago",
        link   : "https://www.youtube.com/watch?v=9dXnqKWtu1M",
        length : "4:59",
        tags   : "HR Songs,Surender Romio,Music,Gaana,Love Songs",
        channel_name : "Supertone Regional",
        channel_logo : "https://yt3.ggpht.com/OklcIVuqBIdsP-YfwSYnJKn8zkwjJZO0ScYPdtX_J3DyZLZIobhsJ3XpGhqgUSJJr02zDl-B0w=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 9,
        card_id: "c9",
        title  : "Bapu Zimidar | Jassi Gill | Replay ( Return Of Melody ) |  Latest Punjabi Songs",
        img    : "https://i.ytimg.com/vi/jOYR3k1VhUQ/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCUG3kjeXMTQuLm4LEQ7gsKKJfrtA",
        views  : "487M",
        time   : "6 years ago",
        link   : "https://www.youtube.com/watch?v=jOYR3k1VhUQ",
        length : "3:44",
        tags   : "Speed Records,Jassi Gill,Music,Punjabi Songs,Gaana",
        channel_name : "Speed records",
        channel_logo : "https://yt3.ggpht.com/ytc/AKedOLRpmI7xqZokzQs9GYYiKlSMD3XTj9RBwnAmhFQGKQ=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        vid_id : 10,
        card_id: "c10",
        title  : "5 Taara (Full Song) - Diljit Dosanjh | Latest Punjabi Songs 2015 | Speed Records",
        img    : "https://i.ytimg.com/vi/MsTW5cyWKj4/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCHrsX3BnCkka_kY9MckgLTvAz0UQ",
        views  : "172M",
        time   : "5 years ago",
        link   : "https://www.youtube.com/watch?v=MsTW5cyWKj4",
        length : "3:44",
        tags   : "Speed Records,Diljit Dosanjh,Punjabi Songs,Gaana,Music,Entertainment",
        channel_name : "Speed Records",
        channel_logo : "https://yt3.ggpht.com/ytc/AKedOLRpmI7xqZokzQs9GYYiKlSMD3XTj9RBwnAmhFQGKQ=s68-c-k-c0x00ffffff-no-rj",
    },
]
export default videos;

// {
//     vid_id : 1,
//     title  : "",
//     img    : "",
//     views  : "",
//     time   : "",
//     link   : "",
//     length : "",
//     tags   : "",
//     channel_name : "",
//     channel_logo : "",
// },